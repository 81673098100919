<template>
  <div class="app-body" :class="locale">
    <router-view />
  </div>
  <!-- v-if="loadingShow" -->
  <div class="dflex flexCenter common-loading-body" v-if="loadingShow">
    <van-loading />
  </div>
</template>

<script>
// router
import { useRouter, useRoute } from 'vue-router'
import store from './store'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

export default {
  setup() {
    const router = useRouter()
    const route = useRoute()
    const loadingShow = computed(() => {
      return store.getters.loadingShow
    })
    const { t, locale } = useI18n()

    return {
      loadingShow,
      locale,
    }
  },
}
</script>

<style lang="less" scoped>
.app-body {
  height: 100%;
  max-width: 750px;
  margin: 0 auto;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #f7f7f7;
  position: relative;
  box-sizing: border-box;
}
.common-loading-body {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 1;
  animation: loadingAnime 1s 1;
}
.app-body.zh-CN {
  font-family: 'PingFang SC', 'Microsoft YaHei', 'Helvetica Neue', Helvetica,
    Arial, sans-serif;
}
.app-body.zh-TW {
  font-family: 'PingFang TC', 'Microsoft JhengHei', 'Helvetica Neue', Helvetica,
    Arial, sans-serif;
}
.app-body.en-US {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.app-body.ja-JP {
  font-family: 'Hiragino Sans', 'Hiragino Kaku Gothic ProN', 'Meiryo',
    sans-serif;
}
@keyframes loadingAnime {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
