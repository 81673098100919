import { createI18n } from 'vue-i18n'
import { getLang } from '@/utils/utils'
// 国际化
// let lang = getLang()
const i18n = createI18n({
    locale: 'en-US',
    fallbackLocale: 'en-US',
    warnHtmlInMessage: 'off',
    messages: {
        "en-US": require('./en-US.js'), // 英语语言包
        "zh-CN": require('./zh-CN.js'), // 简体中文语言包
        "zh-TW": require('./zh-TW.js'), // 繁体中文语言包
        "ja-JP": require('./ja-JP.js'), // 日文语言包
    }
})

export default i18n
